/* src/components/CookieBanner.css */

.cookie-banner {
  background-color: white !important; /* Change background to white */
  color: #000000 !important;
  padding: 20px !important;
  font-size: 16px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1) !important; /* Slight shadow on top */
}

.cookie-content {
  display: flex !important;
  align-items: center !important;
}

.cookie-image {
  width: 50px !important;
  height: 50px !important;
  margin-right: 15px !important;
}

.cookie-text {
  margin: 0 !important;
  color: #000000 !important;
}

.cookie-text.bold {
  font-weight: bold !important;
}

.cookie-link {
  color: #23576a !important; /* sunday_info */
  text-decoration: underline !important;
}

.cookie-button,
.cookie-decline-button {
  border-radius: 5px !important;
  padding: 10px 20px !important;
  margin-left: 10px !important;
  color: #ffffff !important;
  transition: filter 150ms !important;
}

.cookie-button {
  background-color: #8cc29e !important; /* sunday_success */
}

.cookie-decline-button {
  background-color: #fb8b4e !important; /* sunday_warning */
}

.cookie-button:hover,
.cookie-decline-button:hover {
  filter: brightness(95%) !important;
}
