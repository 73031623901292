@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

:root {
  --toastify-color-success: #558451 !important;
  --toastify-color-warning: #fb8b4e !important;
  --toastify-color-error: #f6476d !important;
  --toastify-color-info: #23576a !important;
  --sunday_secondary: theme("colors.sunday_secondary.DEFAULT");
}

html {
  @apply font-poppins;
  overflow-y: scroll;
}

/* Filter Styles */
/* .react-datepicker__header {
  background-color: var(--sunday_secondary) !important;
} */

/* Calendar in create/edit event styles */
.rdrDayToday .rdrDayNumber span:after {
  background: var(--sunday_secondary) !important;
}

.rdrCalendarWrapper {
  border-radius: 0 0 0.5rem 0.5rem !important;
}

.rdrDateDisplayWrapper {
  border-radius: 0.5rem 0.5rem 0 0 !important;
}

.rdrDateDisplayWrapper {
  background-color: var(--sunday_secondary) !important;
}

/* Toastify Styles */
/* .Toastify__toast-container {
  flex-direction: column;
  width: 100%
} */

.Toastify__toast {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.35) !important;
  padding: 15px !important;
}

.auth-wrapper {
  flex-direction: row;
}

.vr {
  margin: 20px 0;
}

.input-icon-wrapper {
  position: relative;
  display: inline-block;
}

.input-icon {
  position: absolute;
  left: 17px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
  z-index: 10;
}

.input-icon-end {
  left: unset;
  right: 17px;
}

.input-with-icon {
  padding-left: 40px !important;
  border: 2px solid rgb(122, 180, 238) !important;
  border-radius: 25px !important;
  height: 50px !important;
}

.input-without-icon {
  padding-left: 20px !important;
  border: 2px solid rgb(122, 180, 238) !important;
  border-radius: 25px !important;
}

.input-without-icon-create {
  border: 2px solid rgb(122, 180, 238) !important;
  border-radius: 25px !important;
  height: 50px !important;
  padding-bottom: 7px !important;
  padding-left: 15px !important;
  margin-bottom: 15px !important;
}

.input-without-icon-create-small {
  border: 2px solid rgb(122, 180, 238) !important;
  border-radius: 25px !important;
  height: 40px !important;
  padding-bottom: 7px !important;
  margin-right: 7px !important;
  margin-bottom: 7px !important;
}

.input-without-icon-create-textarea {
  border: 2px solid rgb(122, 180, 238) !important;
  border-radius: 25px !important;
  padding-left: 15px !important;
  padding-top: 10px !important;
  margin-bottom: 15px !important;
  resize: none;
}

.no-margin {
  margin: 0 !important;
}

.input-with-icon-search {
  height: auto !important;
}

.sub-category-wrapper {
  margin-bottom: 15px;
}

.form-check-input[type="checkbox"] {
  border: 1px solid rgb(122, 180, 238);
}

.auth-wrapper-login {
  justify-content: space-around !important;
}

.navbar {
  z-index: 20;
  position: relative;
}

.navbar-collapse {
  background-color: white;
}

/* Event Card Styles */
/* .card-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: unset !important;
  border-radius: 15px !important;
  box-shadow: 0 4px 2px rgba(0, 0, 0, 0.25) !important;
  overflow: hidden;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
} */

/* .card-image {
  width: 100%;
  height: 0;
  padding-top: 56.25%; 
  background-size: cover;
  background-position: center;
}

.card-body {
  padding: 5px !important;
}

.card-link {
  color: white;
  text-decoration: none;
}

.card-text {
  color: rgb(50, 50, 50);
}

.card-container:hover {
  transform: scale(1.05);
}

.event-details-wrapper {
  width: 70% !important;
} */

.form-header {
  font-size: 20px;
  font-family: BudujSansRegular;
  color: rgb(86, 86, 86);
}

/* Button Styles */
.scaling-button {
  font-size: clamp(
    14px,
    2vw,
    18px
  ) !important; /* Minimum of 12px, maximum of 20px, responsive in between */
}

.button-hover-effect {
  transition: background-color 0.3s ease !important;
}

.button-hover-effect:hover {
  background-color: rgba(255, 0, 0, 0.1) !important;
}

.button-hover-effect-red {
  position: relative;
  transition: background-color 0.3s ease !important;
}

.button-hover-effect-red #trash-icon {
  color: black;
  transition: color 0.15s ease;
}

.button-hover-effect-red:hover {
  background-color: rgba(255, 0, 0, 0.5) !important;
  color: white;
}

.button-hover-effect-red:hover #trash-icon {
  color: white;
}

.btn-light-pink {
  background-color: #ffaebc !important;
  border-color: #ffaebc !important;
  color: #000000 !important;
}

.btn-light-pink:hover {
  background-color: #f7a6b8 !important;
  border-color: #f7a6b8 !important;
}

.btn-light-pink:focus,
.btn-light-pink.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 174, 188, 0.5) !important;
}

.btn-teal {
  background-color: #a0e7e5 !important;
  border-color: #a0e7e5 !important;
  color: #000000 !important;
}

.btn-teal:hover {
  background-color: #8ce0de !important;
  border-color: #8ce0de !important;
}

.btn-teal:focus,
.btn-teal.focus {
  box-shadow: 0 0 0 0.2rem rgba(160, 231, 229, 0.5) !important;
}

.btn-light-green {
  background-color: #b4f8c8 !important;
  border-color: #b4f8c8 !important;
  color: #000000 !important;
}

.btn-light-green:hover {
  background-color: #9df4a9 !important;
  border-color: #9df4a9 !important;
}

.btn-light-green:focus,
.btn-light-green.focus {
  box-shadow: 0 0 0 0.2rem rgba(180, 248, 200, 0.5) !important;
}

.btn-light-orange {
  background-color: #fbe7c6 !important;
  border-color: #fbe7c6 !important;
  color: #000000 !important;
}

.btn-light-orange:hover {
  background-color: #f6d8a1 !important;
  border-color: #f6d8a1 !important;
}

.btn-light-orange:focus,
.btn-light-orange.focus {
  box-shadow: 0 0 0 0.2rem rgba(251, 231, 198, 0.5) !important;
}

/* Google Sign in */
.g-signin2 > div {
  marign: 0 auto;
}

/* Event Description */
.event-description :is(h1, h2, h3, h4, h5, h6) {
  margin-bottom: 0.5em !important;
}

.event-description p,
ul {
  margin-top: 0 !important;
  margin-bottom: 10px !important;
}

.event-description ul,
.event-description ol {
  list-style: unset !important;
  margin-block-start: 1em;
  /* margin-block-end: 1em; */
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  /* padding-inline-start: 40px !important; */
}

/* Ticket Payment Styles */
.event-info strong {
  font-family: Poppins;
}

/* Media queries */
@media (max-width: 1199px) {
  .event-details-wrapper {
    width: 100% !important;
  }

  .welcome-wrapper {
    margin-top: 20px;
  }

  .auth-wrapper {
    flex-direction: column;
  }

  .auth-wrapper-login {
    justify-content: center !important;
  }

  .vr {
    width: 100% !important;
    min-height: 1px !important;
    height: 1px;
  }

  #register-form {
    margin: 10px 0 60px 0;
  }
}

@media (max-width: 768px) {
  #event-info-btns .col {
    padding: 2px !important;
  }
}

@keyframes fadeInTop {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInBottom {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fade-in-top {
  animation: fadeInTop 1s ease-in-out forwards;
}

.fade-in-bottom {
  animation: fadeInBottom 1s ease-in-out forwards;
}

.fade-in-right {
  animation: fadeInRight 1s ease-in-out forwards;
}

.fade-in-left {
  animation: fadeInLeft 1s ease-in-out forwards;
}
