/* Slider dots */
/* Slider dots */
.slick-dots {
  position: absolute;
  bottom: 16px;
  left: 40px;
  display: flex !important;
  margin: 0;
  padding: 0;
  list-style: none;
  z-index: 3;
}

.slick-dots li {
  margin: 0 4px;
}

.slick-dots li button:before {
  font-size: 8px;
  color: #6f7287;
  opacity: 0.5;
}

.slick-dots li.slick-active button:before {
  color: #6f7287;
  opacity: 1;
}

/* Banner structure */
.banner-wrapper {
  width: 100%;
  background: #f8f7fa;
  padding: 20px 0;
  overflow: hidden;
}

.banner-container {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
}

.banner-slide {
  position: relative;
  width: 100%;
  height: 320px;
  background: #fff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 12px rgba(0,0,0,0.1);
  display: flex !important;
}

.banner-image-container {
  position: relative;
  width: 55%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 1400px;
  max-height: 788px;
}

.banner-content {
  width: 45%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
}

.banner-title {
  font-size: 2.5rem;
  font-weight: 800;
  color: #1e0a3c;
  margin-bottom: 16px;
  line-height: 1.2;
}

.banner-subtitle {
  font-size: 1.125rem;
  color: #39364f;
  margin-bottom: 24px;
  line-height: 1.5;
}

.banner-cta-button {
  padding: 12px 24px;
  font-size: 0.875rem;
  font-weight: 600;
  color: #fff;
  background: #d1410c;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
  width: fit-content;
}

.banner-cta-button:hover {
  background: #b23209;
}

@media (max-width: 1024px) {
  .banner-slide {
    height: 300px;
  }

  .banner-title {
    font-size: 2rem;
  }
}

@media (max-width: 768px) {
  .banner-wrapper {
    padding: 16px;
  }

  .banner-slide {
    height: auto;
    flex-direction: column-reverse;
  }

  .banner-content {
    width: 100%;
    padding: 24px;
    text-align: center;
    align-items: center;
  }

  .banner-image-container {
    width: 100%;
    height: 200px;
  }

  .banner-title {
    font-size: 1.75rem;
    margin-bottom: 12px;
  }

  .banner-subtitle {
    font-size: 1rem;
    margin-bottom: 20px;
  }

  .slick-dots {
    left: 50%;
    transform: translateX(-50%);
    bottom: 12px;
  }

  .banner-image {
    max-width: 800px;
    max-height: 400px;
  }
}

@media (max-width: 480px) {
  .banner-wrapper {
    padding: 12px;
  }

  .banner-content {
    padding: 20px 16px;
  }

  .banner-image-container {
    height: 180px;
  }

  .banner-title {
    font-size: 1.5rem;
  }

  .banner-subtitle {
    font-size: 0.875rem;
  }

  .banner-cta-button {
    padding: 10px 20px;
    font-size: 0.875rem;
  }
}
.banner-image-container {
  position: relative;
  width: 55%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 16/9; /* Forces 16:9 aspect ratio */
}

@media (max-width: 768px) {
  .banner-image-container {
    width: 100%;
    aspect-ratio: 2/1; /* Changes to 2:1 on mobile */
  }
}